import React, { useEffect, useState } from "react";

const CarYear = ({ setcarYear, setIndex, carYearOptions, themeData }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [options, setoptions] = useState();
  const [inputVal, setInputVal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setoptions(carYearOptions);
    setFilteredOptions(carYearOptions);
  }, [carYearOptions]);

  const onCHangeHandle = (e) => {
    const value = e.target.value;
    setInputVal(value);

    const filtered = options.filter((option) =>
      option.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const onClickHandle = (option, i) => {
    i === "carYear" && setIndex(4);
    setInputVal(option);
    setcarYear(option);
    setIsOpen(false);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const style = {
    color: themeData.theme_settings.primary_color,
  };
  return (
    <>
      <h2 style={{color:themeData.theme_settings.secondary_color}}>Select Year</h2>
      <input
        type="text"
        className="my-3 master_input"
        name=""
        id="input"
        value={inputVal}
        onFocus={() => setIsOpen(true)}
        onChange={(e) => onCHangeHandle(e)}
      />

      {!filteredOptions && <div className="loader"></div>}

      <div className="dropdown">
        <div className="dropdown_box">
          {filteredOptions &&
            filteredOptions.map((curElem, index) => {
              return (
                <span
                  key={index}
                  onClick={() => onClickHandle(curElem, "carYear")}
                  style={
                    selectedItem && selectedItem === curElem ? style : null
                  }
                  onMouseEnter={() => handleItemClick(curElem)}
                  onMouseLeave={() => handleItemClick(null)}
                >
                  {curElem}
                </span>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CarYear;
