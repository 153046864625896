import React from "react";
import { FaUmbrella } from "react-icons/fa";

const CoverInfo = ({ coverInformation,themeData }) => {
  return (
    <>
      <div className="live_sale_details mt-4">
        <div className="d-flex justify-content-between my-3">
          <h3 style={{color:themeData.theme_settings.primary_color}}>Cover Information</h3>
          <FaUmbrella style={{color:themeData.theme_settings.primary_color}}/>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Still Under Warranty</h5>
          <span>
            {coverInformation.underOriginalWarranty === true ? "Yes" : "No"}
          </span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Months Left Under warranty</h5>
          <span>{coverInformation.warrantyLeft}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Distance Left Under warranty</h5>
          <span>{coverInformation.warrantyDistanceLeft}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Still Under Maintenance Plan</h5>
          <span>
            {coverInformation.underMaintenancePlan === true ? "Yes" : "No"}
          </span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Months Left On Maintenance Plan</h5>
          <span>{coverInformation.maintenancePlanPeriodLeft}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Distance Left On Maintenance Plan</h5>
          <span>{coverInformation.maintenancePlanDistanceLeft}</span>
        </div>
      </div>
    </>
  );
};

export default CoverInfo;
