import React, { useEffect, useState } from "react";

const CarVariant = ({
  setcarVerient,
  setIndex,
  carVerientOptions,
  themeData,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [options, setoptions] = useState(carVerientOptions);
  const [inputVal, setInputVal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(carVerientOptions);

  useEffect(() => {
    setoptions(carVerientOptions);
    setFilteredOptions(carVerientOptions);
  }, [carVerientOptions]);

  const onCHangeHandle = (e) => {
    const value = e.target.value;
    setInputVal(value);

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const onClickHandle = (option, i) => {
    i === "carVerient" && setIndex(5);
    setInputVal(option);
    setcarVerient(option);
    setIsOpen(false);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const style = {
    color: themeData.theme_settings.primary_color,
  };

  return (
    <>
      <h2 style={{color:themeData.theme_settings.secondary_color}}>Select Variant</h2>
      <input
        type="text"
        className="my-3 master_input"
        name=""
        id="input"
        value={inputVal}
        onFocus={() => setIsOpen(true)}
        onChange={(e) => onCHangeHandle(e)}
      />
      {!filteredOptions && <div className="loader"></div>}
      <div className="dropdown">
        <div className="dropdown_box">
          {filteredOptions &&
            filteredOptions.map((curElem, index) => {
              return (
                <span
                  key={index}
                  onClick={() => onClickHandle(curElem, "carVerient")}
                  style={selectedItem === index ? style : null}
                  onMouseEnter={() => handleItemClick(index)}
                  onMouseLeave={() => handleItemClick(null)}
                > 
                  {curElem}
                </span>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CarVariant;
